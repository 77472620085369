import React, { useState } from 'react'
import MergeQuery from '../../../../components/data-collections/merge-collection/merge-query';

const MergeQueryController = (props) => {

    const { setState, prevStepRef, state } = props;



    const onChangeSqlEditorHandler = (sqlQuery) => {
        setState((prevState) => { return { ...prevState, restBody: sqlQuery } });

    }

    console.log('state.restBody', state.restBody)

    return (
        <MergeQuery
            onChangeSqlEditorHandler={onChangeSqlEditorHandler}
            sqlEditorValue={state.restBody}
        />
    )
}

export default MergeQueryController