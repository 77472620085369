import { IconButton } from "@mui/material";
import React, { useMemo } from "react";
import themeColor from "../../../styles/_exports.module.scss";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ASButton } from "../../../shared/as-button/as-button";
import './collection-parameters.scss';
import { AppEnum } from "../../../constants/app-enum";
import { tableBorderRight } from "../../../controllers/data-collections/add-new-data-collection/add-new-data-collection";
import { Draggable } from "react-beautiful-dnd";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { sysDataTypeList } from "./collection-parameters";


const ParametersTableRow = (props) => {

    const { index, isAddNewParameter, isEditMode, collectionParametersCount, ele, showSendAs, editParameter, deleteParameter, isReadOnly = false, provided } = props;


    const disabled = useMemo(() => {
        return isAddNewParameter || isEditMode || (collectionParametersCount == 1)
    }, [isAddNewParameter, isEditMode, collectionParametersCount])


    const getParameterDataTypeName = (ele) => {
        if (!ele) {
            return '';
        }

        const { parameterTypeCD, sysDataTypeId, inputFieldTypeCD } = ele;

        if (parameterTypeCD === AppEnum.ParameterTypeValue.UserDefinedVariable) {
            return sysDataTypeList.find(i => i.dataTypeId === Number(sysDataTypeId))?.dataTypeName || '';
        }

        if (parameterTypeCD === AppEnum.ParameterType.UserInput) {
            return sysDataTypeList.find(i => i.dataTypeId === Number(inputFieldTypeCD))?.dataTypeName || '';
        }

        return '';
    };



    return (
        <>
            {
                !isReadOnly &&
                <TableCell component={'tb'} sx={tableBorderRight}>
                    <IconButton
                        disabled={disabled}
                        {...provided?.dragHandleProps}
                        aria-label="drag handle"
                    >
                        <DragHandleIcon />
                    </IconButton>
                </TableCell>
            }

            <TableCell align="left" component={'td'} sx={tableBorderRight} > {ele?.parameterName} </TableCell>

            <TableCell align="left" component={'td'} sx={tableBorderRight} > {ele?.displayName} </TableCell>

            <TableCell align="left" component={'td'} sx={tableBorderRight} > {ele?.parameterTypeCD} </TableCell>

            {(showSendAs || ele?.sendAs) && <TableCell align="left" component={'td'} sx={tableBorderRight} > {ele?.sendAs} </TableCell>}

            <TableCell align="left" component={'td'} sx={tableBorderRight} >
                {getParameterDataTypeName(ele, sysDataTypeList)}
            </TableCell>

            <TableCell align="left" component={'td'} sx={{ ...(isReadOnly ? {} : tableBorderRight), wordBreak: "break-all" }} >
                {ele?.defaultValue} <span className="default-label">{`${ele?.isFixed ? ' fixed' : ' default'}`}</span>
            </TableCell>

            {
                !isReadOnly &&
                <TableCell align="left" component={'td'}
                >
                    <div className="edit-delete-btn-div">
                        <ASButton sx={{ color: themeColor.linkColor }} disabled={(isAddNewParameter || isEditMode) && !ele.edit}
                            variant="text" onClick={() => editParameter(index)}>
                            EDIT
                        </ASButton>
                        |
                        <ASButton sx={{ color: themeColor.linkColor, padding: '6px 15px' }}
                            variant="text" onClick={() => deleteParameter(index)}
                        >
                            DELETE
                        </ASButton>
                    </div>

                </TableCell>
            }
        </>

    )
}

export default ParametersTableRow