import React from 'react';
import "./ag-grid.scss";
import CreateIcon from '@mui/icons-material/Create';

const CustomAGGridColumnHeader = ({displayName, column, onIconClick }) => {
    return (
        <div className='grid-column-header'>
            <span className='grid-display-name'>{displayName}</span>
            <CreateIcon
                className='grid-edit-icon'
                onClick={(e) => {
                    e.stopPropagation(); // Prevents sorting when clicking the icon
                    onIconClick(e, column);
                }}
            />
        </div>
    );
};

export default CustomAGGridColumnHeader;