import React from 'react';
import "./merge-collection-list.scss";

export default function MergeCollectionList(props) {
    const { state, mergedCollectionsData, openDataVisualizer } = props;

    return (
        <div className='column-selection-list-container'>
            <div className='column-selection-list-name'>
                <span className='column-selection-header'>Select Collections to Edit</span>
            </div>

            <div className='column-list-wrapper'>
                <div className='column-list-view'>
                    {
                        mergedCollectionsData?.map((item, index) => (
                            <div 
                                key={index}
                                className='column-list-item'
                                onClick={(event) => openDataVisualizer(event, item)}
                            >
                                <img 
                                    src={`/assets/${item?.connectionIconImage}`} 
                                    alt={`${item?.collectionName} icon`} 
                                    className="connection-icon"
                                />
                                {item?.collectionName}
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}
