import { Button, Card, Dialog, Backdrop } from "@mui/material";
import React from "react";
import "./ChangePassword.scss";

import { ASTextField } from "../../../shared/as-text-fields";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
export default function ChangePasswordComponent(props) {
  const {
    isPasswordVisible,
    handleSubmit,
    handleTogglePasswordVisibility,
    handlePasswordChange,
    error,
    passwords,
    open,
    handleCloseDialog,
    passwordExists
  } = props;

  return (
      <Dialog open={open} onClose={handleCloseDialog} BackdropComponent={Backdrop}
        BackdropProps={{
          style: { backdropFilter: "blur(3px)" },
          onClick: handleCloseDialog,
        }}
        PaperProps={{ sx: { borderRadius: "10px", width: "520px", overflow: "hidden", minHeight:300, maxHeight: "450px" } }}
        sx={{ display: 'flex', alignItems: 'start', justifyContent: 'center' }}>
        <Card className="container-change-password">
          <div className="header">Change Password</div>
          {error == "" ? "" :
            <div className="error-msg">
              {" "}
              {error && <p>{error}</p>}
            </div>
          }
          {passwordExists ? 
          <div>
            <ASTextField
              className="text-field"
              type={isPasswordVisible.oldPassword ? "text" : "password"}
              fullWidth
              label="Old Password"
              variant="outlined"
              value={passwords.oldPassword}
              onChange={handlePasswordChange("oldPassword")}
            />
            <span
              className="eye-icon"
              onClick={handleTogglePasswordVisibility("oldPassword")}
            >
              {isPasswordVisible.oldPassword ? (
                <VisibilityOff />
              ) : (
                <VisibilityIcon />
              )}
            </span>
          </div>
          :""}
          <div>
            <ASTextField
              className="text-field"
              type={isPasswordVisible.newPassword ? "text" : "password"}
              fullWidth
              label="New Password"
              variant="outlined"
              value={passwords.newPassword}
              onChange={handlePasswordChange("newPassword")}
            />
            <span
              className="eye-icon"
              onClick={handleTogglePasswordVisibility("newPassword")}
            >
              {isPasswordVisible.newPassword ? (
                <VisibilityOff />
              ) : (
                <VisibilityIcon />
              )}
            </span>
          </div>
          <div>
            <ASTextField
              className="text-field"
              type={isPasswordVisible.confirmPassword ? "text" : "password"}
              fullWidth
              label="Confirm Password"
              variant="outlined"
              value={passwords.confirmPassword}
              onChange={handlePasswordChange("confirmPassword")}
            />
            <span
              className="eye-icon"
              onClick={handleTogglePasswordVisibility("confirmPassword")}
            >
              {isPasswordVisible.confirmPassword ? (
                <VisibilityOff />
              ) : (
                <VisibilityIcon />
              )}
            </span>
          </div>

          <div className="change-password-button">
            <Button
              variant="contained"
              color="error"
              type="submit"
              onClick={handleSubmit}
            >
              Change Password
            </Button>
          </div>
        </Card>
      </Dialog>
  );
}
