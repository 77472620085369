import {
    Paper,
    Radio,
    RadioGroup,
    FormControlLabel,
} from "@mui/material";
import MessageToaster from "../../../shared/message-toaster/message-toaster";
import './select-object.scss';

const SelectObject = (props) => {

    const { selectedObjectUId, handleObjectSelection, collectionObjects, errorMessage } = props;

    const renderData = (collectionObjects, itemsPerColumn) => {
        if (!collectionObjects || collectionObjects.length <= 0) {
            return null;
        }

        // Group the data by groupName and childGroupName
        const groupedData = collectionObjects.reduce((groups, item) => {
            const group = item.groupName || '';
            const subGroup = item.childGroupName || '';

            if (!groups[group]) {
                groups[group] = { subGroups: {}, items: [] };
            }

            if (subGroup) {
                if (!groups[group].subGroups[subGroup]) {
                    groups[group].subGroups[subGroup] = [];
                }
                groups[group].subGroups[subGroup].push(item);
            } else {
                groups[group].items.push(item);
            }

            return groups;
        }, {});

        const columns = [];
        let columnIndex = 0;

        Object.keys(groupedData).forEach((group, groupIndex) => {
            const { subGroups, items } = groupedData[group];
            let currentColumnItems = [];
            let itemCount = 0;

            // Add group label
            currentColumnItems.push(
                <div key={`${groupIndex}-group-label`}>
                    <div className="group-label-div">{group}</div>
                </div>
            );
            itemCount++;

            // Iterate over subgroups
            Object.keys(subGroups).forEach((subGroup, subGroupIndex) => {
                const subGroupItems = subGroups[subGroup];

                // Add subgroup label
                currentColumnItems.push(
                    <div key={`${groupIndex}-${subGroupIndex}-subgroup-label`} className="subgroup-label-div">
                        {subGroup}
                    </div>
                );
                itemCount++;

                // Add subgroup items
                subGroupItems.forEach((item, itemIndex) => {
                    currentColumnItems.push(
                        <div key={`${groupIndex}-${subGroupIndex}-${columnIndex}-${itemIndex}`} style={{ marginLeft: '20px' }}>
                            <FormControlLabel
                                value={item.templateCollectionId}
                                control={
                                    <Radio
                                        sx={{
                                            color: "#018786 !important",
                                            '&.Mui-checked': {
                                                color: "#018786 !important",
                                            },
                                        }}
                                    />
                                }
                                label={item.name}
                                sx={{
                                    color: `#6B859E !important`,
                                }}
                            />
                        </div>
                    );
                    itemCount++;
                });
            });

            // Add remaining group items (without subgroup)
            items.forEach((item, itemIndex) => {
                currentColumnItems.push(
                    <div key={`${groupIndex}-${columnIndex}-${itemIndex}`}>
                        <FormControlLabel
                            value={item.templateCollectionId}
                            control={
                                <Radio
                                    sx={{
                                        color: "#018786 !important",
                                        '&.Mui-checked': {
                                            color: "#018786 !important",
                                        },
                                    }}
                                />
                            }
                            label={item.name}
                            sx={{
                                color: `#6B859E !important`,
                            }}
                        />
                    </div>
                );
                itemCount++;
            });

            // Push the current group's items as a new column
            columns.push(
                <div key={`${groupIndex}-column-${columnIndex}`} style={{ marginRight: '20px' }}>
                    {currentColumnItems}
                </div>
            );
            columnIndex++;
        });

        return (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {columns}
            </div>
        );
    };




    return (
        <Paper
            sx={{
                // padding: "20px",
                // minHeight: "346px",
                // width: "1250px",
                // padding: "20px",
                // display: "flex",
                // justifyContent: "space-between",
                padding: "20px",
                minHeight: "346px",
                maxWidth: "1250px",
                display: "flex",

            }}
            ref={props.prevStepRef}
        >
            {
                errorMessage &&
                <MessageToaster errorMessage={errorMessage} />
            }
            <RadioGroup
                defaultValue={selectedObjectUId}
                value={selectedObjectUId}
                onChange={(event) => handleObjectSelection(event)}
            >
                {collectionObjects?.length > 0 ? renderData(collectionObjects, 9) : <span className="subgroup-label-div">No objects found.</span>}
            </RadioGroup>

        </Paper>
    );
};

export default SelectObject;