export const usersList = [
    {
        id: 1,
        name: " user",
        isSelected: false,

    },
    {
        id: 2,
        name: "users",
        isSelected: false,

    },
    {
        id: 3,
        name: "users",
        isSelected: false,

    },
    {
        id: 4,
        name: "users",
        isSelected: false,

    },
    {
        id: 5,
        name: "users",
        isSelected: false,

    },
    {
        id: 6,
        name: "users",
        isSelected: false,

    },
    {
        id: 7,
        name: "users",
        isSelected: false,

    },
    {
        id: 8,
        name: "users",
        isSelected: false,

    },
    {
        id: 9,
        name: "users",
        isSelected: false,

    },
    {
        id: 10,
        name: "users",
        isSelected: false,

    },
    {
        id: 11,
        name: "users",
        isSelected: false,

    },

    {
        id: 12,
        name: "users",
        isSelected: false,

    },
    {
        id: 13,
        name: "users",
        isSelected: false,

    },
    {
        id: 14,
        name: "users",
        isSelected: false,

    },
    {
        id: 15,
        name: "users",
        isSelected: false,

    },
    {
        id: 16,
        name: "users",
        isSelected: false,

    },
    {
        id: 17,
        name: "users",
        isSelected: false,

    },
    {
        id: 18,
        name: "users",
        isSelected: false,

    },

    {
        id: 19,
        name: "users",
        isSelected: false,

    },
    {
        id: 20,
        name: "users",
        isSelected: false,

    },

]

export const hourDataList = [
    {
        id: 1,
        name: " hour",
        isSelected: false,

    },
    {
        id: 2,
        name: " hours",
        isSelected: false,

    },
    {
        id: 3,
        name: " hours",
        isSelected: false,

    },
    {
        id: 4,
        name: " hours",
        isSelected: false,

    },
    {
        id: 5,
        name: " hours",
        isSelected: false,

    },

]

export const fixedPlanList = [
    {
        licenseTypeUId: "AS.FREE.1",
        planName: "Free",
        isSelected: true,
    },
    {
        licenseTypeUId: "AS.PROF.3",
        planName: "Professional",
        isSelected: false,
    },    
    {
        licenseTypeUId: "AS.MIDB.4",
        planName: "Mid-Size Business",
        isSelected: false,
    },
    {
        licenseTypeUId: "AS.ENTP.5",
        planName: "Enterprise",
        isSelected: false,
    }
]


export const SUBSCRIPTION = "subscription";

export const BUYSETUP = "BUY";

export const paymentStatus = "status";

export const planType = "plan";

export class SubscritionDetailsState {
    noOfUsers = 0;
    amount = 0;
    planName = "";
    startDate = "";
    endDate = "";
    licenseTypeUId = "";
}
