import React from "react";
import { ASButton } from "../../../shared/as-button/as-button";
import "./support-view.scss";

const SupportView = (props) => {
    const { onBuyPlan, buttonDisabled } = props;
    return (
        <>
            <div className="support-view-container">
                <div className="support-view-card">
                    <div className="support-view-header">
                        <label>For any kind of support </label>
                        <div className="support-view-text">
                            You can write to <span> support@alphasheet.io </span> for the issue you are facing and
                        </div>
                        <div className="support-view-text">
                            we will immediately respond you or simply click below button
                        </div>
                        <div className="support-view-text">
                            and we will book a calendar with you
                        </div>
                    </div>
                    <div className="support-view-button">
                        <ASButton disabled={buttonDisabled} primary={true} variant="contained" onClick={() => onBuyPlan()}>
                            please book me an expert
                        </ASButton>
                    </div>
                </div>
            </div >
        </>
    );
}

export default SupportView;

