import { Autocomplete, MenuItem, Stack } from '@mui/material';
import { ASTextField } from '../as-text-fields';


const ASAutoComplete = (props) => {

    const { options, selectedOptionValue, onChange, item, value, label, tableContainerRef, onBlur, isMultiSelect = false, className = '' } = props;

    return (
        <Autocomplete
            size={isMultiSelect ? 'medium' : "small"}
            id="variable-dropdown"
            freeSolo
            multiple={isMultiSelect}
            className={`text-field ${className}`}
            options={options}
            disableCloseOnSelect={isMultiSelect}
            disableClearable={false}
            renderOption={(props, option) => {

                return (
                    <MenuItem
                        {...props}
                        key={`option ${option?.key}`}
                        className={(isMultiSelect ? (value.some(v => v.key === option?.key)) : selectedOptionValue === option?.key) ? "autocomplete-selected-option" : "autocomplete-option"}
                    >
                        {
                            option?.connectionIconImage &&
                            <Stack direction={'column'} className='menu-item-icon'>
                                <img alt={`${option?.connectionIconImage}`} src={`/assets/${option?.connectionIconImage}`} />
                            </Stack>
                        }
                        {option?.label}
                    </MenuItem>
                );
            }}
            onChange={(event, option) => {
                if (isMultiSelect) {
                    onChange(option)
                }
                else {
                    onChange(option?.key, item)
                }
            }}

            value={value}
            onOpen={() => {
                document.body.style.overflowY = 'hidden';
                if (tableContainerRef.current) {
                    tableContainerRef.current.style.overflow = 'hidden';  // Disable scroll
                }
            }}
            onClose={() => {
                document.body.style.overflowY = 'auto';
                if (tableContainerRef.current) {
                    tableContainerRef.current.style.overflow = 'auto';
                }
            }}
            onBlur={(event, option) => onBlur?.(event, item)}
            renderInput={(params) => (
                <ASTextField
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: ((item?.defaultValue || value) && params.InputProps.endAdornment),
                    }}
                />
            )}
        />
    )
}

export default ASAutoComplete