import { axios } from "../core/axios.interceptor";
// import { BASE_API } from "./api-url.service";
// import { http } from "./http-request.service";

// // api for adding a new user
// export function saveNewUser(userModel, isFormData) {
//     return http.post(BASE_API, `account/invite-user`, userModel, isFormData);
// }

// // api for getting user list
// export function getAllUsers() {
//     return http.get(BASE_API, `user`);
// }

// // api for getting user's collection data
// export function getUserCollection(userId) {
//     return http.get(BASE_API, `user/${userId}/collection`);
// }

// // api for getting user's collection data
// export function getUserConnection(userId) {
//     return http.get(BASE_API, `user/${userId}/Connection`);
// }

// // api for getting user's role list
// export function getUserRoleList() {
//     return http.get(BASE_API, `master/roles`);
// }

// // api for saving user's collection permission
// export function saveCollectionPermission(userCollectionPermission) {
//     return http.put(BASE_API, `collection/permission`, userCollectionPermission);
// }

// // api for saving user's connection permission
// export function saveConnectionPermission(userConnectionPermission) {
//     return http.put(BASE_API, `Connection/permission`, userConnectionPermission);
// }

const invitedUserbaseURL = "invited-users"; 

// api for adding a new user
export async function saveNewUser(userModel) {
  const response = await axios.post(invitedUserbaseURL, userModel);
  return response;
}
//resend invite
export async function resendInvite(email) {
  const response = await axios.put(`/${invitedUserbaseURL}?email=${email}`);
  return response;
}
//cancel invite 
export async function cancelInvite(uId) {
  const response = await axios.delete(`/${invitedUserbaseURL}/${uId}`);
  return response;
}
// api for getting user list
export async function getAllUsers() {
  const response = await axios.get(`users`);
  return response;
}
// api for getting invited user list
export async function getInvitedAllUsers() {
  const response = await axios.get(`${invitedUserbaseURL}`);
  return response;
}
// api for getting user's collection data
export async function getUserCollection(userId) {
  const response = await axios.get(`users/${userId}/collections`);
  return response;
}

// api for getting user's collection data
export async function getUserConnection(userId) {
  const response = await axios.get(`users/${userId}/connections`);
  return response;
}

// api for getting user's role list
export async function getUserRoleList() {
  const response = await axios.get(`sys/roles`);
  return response;
}

//api for getting wheter user has permission to access the collection or not
export async function getCollectionPermissionByCollectionUId(collectionUId){
  const response = await axios.get(`collections/${collectionUId}/user`);
  return response;
} 

// api for saving user's collection permission
export async function saveCollectionPermission(userCollectionPermission) {
  const response = await axios.put(`collections/${userCollectionPermission?.collectionUId}/users`, userCollectionPermission);
  return response;
}

// api for saving user's connection permission
export async function saveConnectionPermission(userConnectionPermission) {
  const response = await axios.put(`connections/${userConnectionPermission?.connectionUId}/users`, userConnectionPermission);
  return response;
}
//cancel invite 
export async function deleteUser(userId) {
  const response = await axios.delete(`users?userId=${userId}`);
  return response;
}

export async function updateUserRole(userId, userRole) {
  const response = await axios.put(`users/${userId}/role?userRoleId=${userRole}`);
  return response;
} 