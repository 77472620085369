import { MenuItem, Paper, Dialog, Backdrop, Button } from "@mui/material";
import { ASTextField } from "../../../shared/as-text-fields";
import "./column-format-rename.scss"
import { ASButton } from "../../../shared/as-button/as-button";
import { selectedMenuItemBackgroundColor } from "../collection-filter-column/collection-filter-column";
import _ from "lodash";

const ColumnRenameFormatPopup = (props) => {

    const { onListClick, prioritizeEquivalentDataTypes, onSaveClick,
        datatypeDisplayFormate, customColumn, onDisplayNameChange } = props;

    return (
        <div ref={props.prevStepRef}>

            <Dialog open={props?.isOpenDialog} onClose={props?.handleClosePopup} BackdropComponent={Backdrop}
                BackdropProps={{
                    style: { backdropFilter: "blur(3px)" },
                    onClick: props.handleClosePopup,
                }}
                PaperProps={{ sx: { borderRadius: "10px", width: "300px", overflow: "unset" } }}
                sx={{ display: 'flex', alignItems: 'start', justifyContent: 'center' }}>
                <Paper
                    sx={{
                        minHeight: "100px",
                        width: "100%",
                        padding: "20px",
                    }}
                >
                    <div className="column-basic-info-header">Column Settings</div>

                    <div className="basic-info-container" style={{ width: "100%" }}>

                        <ASTextField
                            size="small"
                            className="text-field"
                            label="Rename Column To"
                            variant="outlined"
                            onChange={(event) => onDisplayNameChange(event?.target?.value)}
                            value={customColumn?.displayName}
                        />

                        <ASTextField
                            size="small"
                            className="data-type-list"
                            type={"text"}
                            fullWidth
                            value={customColumn?.dataType}
                            label="Data Type"
                            variant="outlined"
                            select={true}
                            SelectProps={{
                                MenuProps: {
                                    className: "menu-role-div",
                                    sx: selectedMenuItemBackgroundColor,
                                },
                            }}
                        >
                            {
                                prioritizeEquivalentDataTypes?.map((list, index) => {
                                    return (
                                        <MenuItem value={list?.sysDataTypeId} key={`dynamic ${index}`}
                                            onClick={() => {
                                                onListClick(list?.sysDataTypeId, true)
                                            }}>
                                            {(list?.dataBaseDataType == "Int64") ? "Integer" : list?.dataBaseDataType}
                                        </MenuItem>
                                    );
                                })
                            }
                        </ASTextField>

                        <ASTextField
                            size="small"
                            className="display-format-list"
                            type={"text"}
                            fullWidth
                            value={customColumn?.displayFormat}
                            label="Display Format"
                            variant="outlined"
                            select={true}
                            SelectProps={{
                                MenuProps: {
                                    className: "menu-role-div",
                                    sx: selectedMenuItemBackgroundColor,
                                },
                            }}
                        >
                            {
                                datatypeDisplayFormate?.map((list, index) => {
                                    return (
                                        <MenuItem value={list?.displayFormat} key={`dynamic ${index}`}
                                            onClick={() => {
                                                onListClick(list?.displayFormat, false)
                                            }}>

                                            {list?.displayFormat}
                                        </MenuItem>
                                    );
                                })
                            }
                        </ASTextField>

                    </div>

                    <div className="buttons-flex">
                        <Button className="column-rename-cancel-button" onClick={props.handleClosePopup}>
                            Cancel
                        </Button>
                        {(!customColumn?.dataType || !customColumn?.displayFormat || !customColumn?.displayName) ?
                            <ASButton variant="contained" primary={false}>
                                Save
                            </ASButton>
                            :
                            <ASButton variant="contained" onClick={() => { onSaveClick() }} primary={true}>
                                Save
                            </ASButton>
                        }
                    </div>
                </Paper>
            </Dialog>
        </div >
    );
};

export default ColumnRenameFormatPopup;