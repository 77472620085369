import { Fragment } from "react";
import { AppEnum } from "../../../../constants/app-enum";

export const RestApiNavView = ({ navData, selectedTab = AppEnum.RestConnnectionTypes.No_Auth, setTab }) => (
    <>
        {navData.map((ele) => (
            <Fragment key={ele.for}>
                <input
                    hidden
                    type="radio"
                    id={ele.for}
                    name="radiogroup"
                    value={ele.label}
                    checked={ele.label === selectedTab}
                    onChange={() => setTab(ele.label)}
                />

                <div className="rest-nav-item">
                    <p>
                        <label htmlFor={ele.for}>{ele.label}</label>
                    </p>
                </div>

            </Fragment>
        ))}
    </>
);
