import { CollectionStepLabelConstant } from '../../../../constants/as-stepper-step-label'
import SelectConnectionController from '../select-connection/select-connection-controller'
import MergeCollectionParametersController from './merge-collection-parameters-controller'
import MergeQueryController from './merge-query-controller'
import { AppEnum } from '../../../../constants/app-enum'
import SelectCollectionController from './select-collections-controller'

const MergeCollectionSteps = (props) => {

    const { resetErrorMessages, state, setState, prevStepRef, getCollectionTypeList, setSelectedConnection, setParametersState, selectedConnectionSubmitted,
        selectedConnection, isTestingConnection, mergeCollectionState, commonRestSteps, setMergeCollectionState
    } = props


    if (!state.hasMergeCollections) {
        return []
    }
    

    return [
        {
            label: CollectionStepLabelConstant.Select_Connection,
            description: (
                <SelectConnectionController
                    resetErrorMessages={resetErrorMessages}
                    state={state}
                    setState={setState}
                    prevStepRef={prevStepRef}
                    getCollectionTypeList={getCollectionTypeList}
                    setSelectedConnection={setSelectedConnection}
                    setParametersState={setParametersState}
                />
            ),
            isSelectConnection: true,
            isTestingConnection: isTestingConnection,
            optionalComponent: { labels: selectedConnectionSubmitted, },
            isCollectionType: true,
            disabled: !state.hasMergeCollections
        },
        {
            label: CollectionStepLabelConstant.Select_Objects,
            description: (
                <SelectCollectionController
                    // resetErrorMessages={resetErrorMessages}
                    prevStepRef={prevStepRef}
                    state={state}
                    setState={setState}
                    selectedConnection={selectedConnection}
                    setMergeCollectionState={setMergeCollectionState}
                    mergeCollectionState={mergeCollectionState}
                />
            ),
            isTestingConnection: isTestingConnection,
            disabledBack: false,
            optionalComponent: { labels: mergeCollectionState?.selectedObjectCollections.map(c => c.label), isSelectCollectionObjectsPreview: true },
            disabled: state.collectionUIdsToMerge?.length === 0,
        },
        {
            label: CollectionStepLabelConstant.Merge_Query,
            description: (
                <MergeQueryController
                    // isTemplateCompany={isTemplateCompany}
                    // resetErrorMessages={resetErrorMessages}
                    state={state}
                    setState={setState}
                    prevStepRef={prevStepRef}
                // setParametersState={setParametersState}            
                />
            ),
            isTestingConnection: isTestingConnection,
            optionalComponent: { labels: state?.restBody, isMergeQueryStep: true },

            disabledBack: false,
            disabled: !state?.restBody
        },
        {
            label: CollectionStepLabelConstant.Parameters,
            description: (
                <MergeCollectionParametersController
                    // isTemplateCompany={isTemplateCompany}
                    // resetErrorMessages={resetErrorMessages}
                    state={state}
                    setState={setState}
                    prevStepRef={prevStepRef}
                // setParametersState={setParametersState}            
                />
            ),
            isTestingConnection: isTestingConnection,
            isParameterSubmitted: !!state.collectionParameters.length,
            optionalComponent: { collectionParameters: state.collectionParameters, showSendAs: state.sysCollectionTypeId == AppEnum.SysCollectionTypeId.REST },
            disabledBack: false,
            disabled: false
        },
        ...commonRestSteps
    ]

}

export default MergeCollectionSteps