import { Tabs, Tab } from "@mui/material";
import "./collection-steps-view.scss";

export const CollectionStepHeaderView = ({ steps, activeStep, handleStepClick }) => {
    return (
        <div className={"collection-nav-container"}>
            <Tabs
                value={activeStep}
                onChange={(event, newValue) => handleStepClick(steps[newValue]?.label)}
                variant={"scrollable"}
                scrollButtons="auto"
                className="mui-tabs"
                aria-label="Collection Step Navigation"
            >
                {steps?.map((ele, index) => (
                    <Tab
                        key={ele.label}
                        label={ele.label}
                        className="collection-nav-item"
                    />
                ))}
            </Tabs>
        </div>
    );
};
