import React, { Fragment, useMemo } from "react";
import "../../user-permission/user-permission.scss";
import { Divider, InputAdornment, ListItem, ListItemText, MenuItem, Paper } from "@mui/material";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { muiCheckboxStyle } from "../../user-permission/user-permission";
import CloseIcon from '@mui/icons-material/Close';
import { ASTextField } from "../../../shared/as-text-fields";
import { ASUsers } from "../../../shared/menu-icons/as-users";
import { AppEnum } from "../../../constants/app-enum";

const UsersPermissionForCollection = (props) => {

    const { userList, searchedUserList } = props.state;
    const { searchUserRef } = props;

    const permittedUsers = useMemo(() => {

        return userList.filter((user) => user.hasPermission || user.userRole === AppEnum.UserRoleIds.CompanyAdmin ||
            user.userRole === AppEnum.UserRoleIds.CollectionAdmin);
    }, [userList])



    return (
        <div>
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }} className='report-table-paper'>
                    <ListItem>
                        <ListItemText>

                            <div className="report-permission-header">

                                <div className="report-svg">
                                    <ASUsers className="menu-image" flag={false} />

                                    <div className="report-label">
                                        <span>Users Permission</span>
                                        <label>Select the users to give them access for this collection</label>
                                    </div>
                                </div>
                                <div className="search-collection-input">
                                    <ASTextField
                                        className="text-field"
                                        fullWidth
                                        label="Search"
                                        key="Search"
                                        variant="outlined"
                                        onChange={(event) => {
                                            props.onChangeSearchUserHandler(event.target.value);
                                        }}
                                        inputRef={searchUserRef}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <CloseIcon className="clear-icon" onClick={() => props.onChangeSearchUserHandler(null)} />
                                            </InputAdornment>,
                                        }}
                                    />
                                </div>
                            </div>

                        </ListItemText>

                    </ListItem>

                    <Divider />

                    <TableContainer>

                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size='medium'
                        >
                            <TableHead>
                                <TableRow>

                                    <TableCell padding="checkbox">

                                        <Checkbox
                                            color="primary"
                                            checked={permittedUsers?.length === userList?.length}
                                            onChange={(event) => props.onCheckAllUserHandler(event)}
                                            sx={muiCheckboxStyle}
                                        />

                                    </TableCell>

                                    {
                                        userTableHeaderRowCells.map((headCell, index) => {

                                            return (
                                                <TableCell
                                                    key={headCell.id}
                                                    align='left'
                                                    className={index === 0 ? "name-cell" : ''}
                                                    id={`connectionCheckbox${index}`}
                                                    scope="row"
                                                    padding={index === 0 ? "none" : 'normal'}
                                                >
                                                    {headCell.label}
                                                </TableCell>)
                                        })
                                    }

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    (searchUserRef?.current?.value ? searchedUserList : userList).map((user, index) => {

                                        return (
                                            <TableRow
                                                hover
                                                onClick={() => props.saveUserPermission(!user?.hasPermission, user)}
                                                role="checkbox"
                                                aria-checked={user?.hasPermission}
                                                tabIndex={-1}
                                                key={index}
                                                selected={user?.hasPermission && (user?.userRole !== AppEnum.UserRoleIds.CompanyAdmin)}
                                            >
                                                <TableCell >

                                                    <Checkbox
                                                        color="primary"
                                                        checked={user?.hasPermission ||
                                                            user?.userRole === AppEnum.UserRoleIds.CompanyAdmin}
                                                        disabled={user?.userRole === AppEnum.UserRoleIds.CompanyAdmin}
                                                        sx={muiCheckboxStyle}
                                                    />

                                                </TableCell>

                                                <TableCell
                                                    className="name-cell"
                                                    id={`connectionCheckbox${index}`}
                                                    scope="row"
                                                    padding="none"
                                                >
                                                    {user?.fullName}
                                                </TableCell>

                                                <TableCell
                                                    className="name-cell"
                                                    id={`userRole${index}`}
                                                    scope="row"
                                                    padding="none"
                                                >
                                                    {user?.userRoleName}
                                                </TableCell>

                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
        </div>
    )
}

export default UsersPermissionForCollection;

// connection header column lables
const userTableHeaderRowCells = [
    { id: 'collection', numeric: false, disablePadding: true, label: 'User name' },
    { id: 'userRole', numeric: false, disablePadding: false, label: 'User role' },
];