import React, { Fragment } from "react";
import { Button, MenuItem, Backdrop } from "@mui/material";
import { ASTextField } from "../../../shared/as-text-fields";
import "./add-new-user.scss";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import styles from "../../../styles/_exports.module.scss";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { FormHeader } from "../../login-signup/login-signup";
import { selectedMenuItemBackgroundColor } from "../../data-collections/collection-parameters/collection-parameters";
import MessageToaster from "../../../shared/message-toaster/message-toaster";
import { ASButton } from "../../../shared/as-button/as-button";

// customized password tooltip
const PasswordToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: styles.avatarBackground,
    color: styles.secondaryColor,
    fontSize: theme.typography.pxToRem(14),
    border: `1px solid ${styles.text_2}`,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddNewUser = (props) => {
  const { state, userRole, open, handleCloseDialog} = props;

  return (
    <Fragment>
      <Dialog open={open} handleCloseDialog={handleCloseDialog} BackdropComponent={Backdrop}
        BackdropProps={{
          style: { backdropFilter: "blur(3px)" },
          onClick: handleCloseDialog,
        }}
        PaperProps={{ sx: { borderRadius: "10px", width: "520px" , maxHeight: "600px"} }}
        sx={{ display: 'flex', alignItems: 'start', justifyContent: 'center' }}>
        <div className="user-form-container">
        <div className="header">Invite User</div>

          {state.errorMessage && (
            <MessageToaster errorMessage={state.errorMessage} />
          )}
          <ASTextField
            className="text-field"
            type="text"
            fullWidth
            label="Full Name"
            variant="outlined"
            value={state.Name}
            onBlur={props.onBlurFullnameHandler}
            onChange={props.onChangeFullNameHandler}
            sx={state.isFullNameValid ? "" : {marginBottom:"0px !important"}}
          />
          {state.isFullNameValid ? (
            ""
          ) : (
            <label className="invalid-field" style={{display:" -webkit-inline-box"}}>Full name is invalid</label>
          )}

          <ASTextField
            className="text-field"
            fullWidth
            label="Email"
            key="Email"
            variant="outlined"
            value={state.email}
            onChange={props.onChangeEmailHandler}
            onBlur={props.onBlurEmailHandler}
          />
          {state.isEmailValid ? (
            ""
          ) : (
            <label className="invalid-field">Email is invalid</label>
          )}
          <div>
            <ASTextField
              className="text-field"
              fullWidth
              label="Role"
              variant="outlined"
              select={true}
              value={state.RoleId}
              onChange={props.onChangeRoleHandler}
              SelectProps={{
                MenuProps: {
                  className: "menu-role-div",
                  sx: selectedMenuItemBackgroundColor,
                },
              }}
            >
              {userRole?.map((role) => (
                <MenuItem key={role.id} value={role.id} name={role.normalizedName
                }>
                  {role.normalizedName}
                </MenuItem>
              ))}
            </ASTextField>
            {state.isUserRoleIdValid ? (
              ""
            ) : (
              <label className="invalid-field">Role is invalid</label>
            )}
          </div>

          <div className="invite-buttons" >
          <Button className="invite-cancel-button" onClick={() => props.cancelAddingUser()}>
              Cancel
            </Button>
          <div className="save-btn-div">           
            
            <ASButton
              variant="contained"
              className="button-primary"
              primary={true}
              onClick={() => props.saveNewUser()}
            >
              Invite User
            </ASButton>

          </div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default AddNewUser;
