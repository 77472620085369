import React, { useEffect, useRef, useState } from 'react'
import CheckoutView from '../../../components/checkout-process/checkout/CheckoutView'
import { addBuyPlan, getSubscription, getSubscriptionPlan, updateSubscriptionPlan } from '../../../services/checkout-service';
import { fixedPlanList, usersList, hourDataList, SUBSCRIPTION, BUYSETUP, SubscritionDetailsState } from './Checkout.state';
import { UNSAFE_ViewTransitionContext, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import UpdateCheckoutPrice from '../../../components/checkout-process/checkout/UpdateCheckoutPrice';
import MuiDialog from '../../../shared/mui-dialog/mui-dialog';
import { refreshTokenHandler } from '../../../core/axios.interceptor';
import { AppEnum } from '../../../constants/app-enum';
import { toast } from 'react-toastify';

function Checkout(props) {
    const [subscriptionList, setSubscription] = useState([]);
    const [planList, setPlanList] = useState([]);
    const [featureData, setFatureData] = useState({});
    const [developerPrice, setdeveloperPrice] = useState("");
    const [userList, setUserList] = useState([]);
    const [hoursList, setHoursList] = useState([]);
    const [user, setUser] = React.useState(1);
    const [hour, setHour] = React.useState(1);
    const [totalPrice, setTotalPrice] = React.useState(0);
    const [isDeveloperSetUp, setDeveloperSetUp] = React.useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [boughtPlanDetails, setBoughtPlanDetails] = useState(new SubscritionDetailsState());
    const [isUpdatePayment, setIsUpdatePayment] = useState(false);
    const [isUpdateSubscription, setIsUpdateSubscription] = useState(false);
    const [unusedAmount,setUnusedAmount] = useState(0);
    const [subscriptionUId,setSubscriptionUId] = useState(0);
    const [paymentPopup,setPaymentPopup] = useState(false);
    const [paymentUrl,setPaymentUrl] = useState();
    const isMounted = useRef(null);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    let params = searchParams.get(SUBSCRIPTION);

    const location = useLocation();
    const navigate = useNavigate();
    
    useEffect(() => {
        isMounted.current = true;
        setPlanList(fixedPlanList);
        getSubscriptionPlanDetails();
        setUserList(usersList)
        setHoursList(hourDataList)
        setIsUpdateSubscription(props.pFlag == "update" ? true : false);
        if(props.pFlag == BUYSETUP){
            setDeveloperSetUp(true);
        }
        return () => {
            isMounted.current = false;
            let newPlanList = [...fixedPlanList];
            newPlanList.forEach((item) => {
            item.isSelected = false;
            if (item.licenseTypeUId == "AS.FREE.1") {
                item.isSelected = true;
            }
            setPlanList([...newPlanList])
        })}        
    }, []);

    useEffect(()=>{
        if(props.pFlag == BUYSETUP){
            setTotalPrice(hour * developerPrice);
            setIsUpdateSubscription(false);
            setDeveloperSetUp(true);
        }
    },[props.pFlag])
    
    useEffect(() => {
        isDeveloperSetUp ? setTotalPrice((user * featureData?.pricePeryear) + (hour * developerPrice)) : setTotalPrice((user * featureData?.pricePeryear));
        if(props.pFlag == BUYSETUP){
            setTotalPrice(hour * developerPrice);
        }
        if(isUpdateSubscription){
            if(user * featureData?.pricePeryear == boughtPlanDetails.amount){
                setIsUpdatePayment(false);
                setUnusedAmount(0);
                setTotalPrice(0);
            }
            else{
                calculateNewPrice();
            }
        }        
    }, [hour, user, featureData , boughtPlanDetails]);

    useEffect(() => {
        if(featureData && subscriptionList.length > 0){
            handleMaxNoOfUser(featureData.licenseTypeUId);
        }
        if(featureData.name == "Beginner" && user > 5){
            setUser(5);
        }
        else if(featureData.name == "Free" && user > 1){
            setUser(1);
        }
    },[subscriptionList,featureData])

    const getSubscriptionDetails = () => {
        getSubscription().then((res) => {
            // if(res && res.data.length == 1 && res.data[0].noOfUsers == 0 &&
            //     res.data[0].statusCD == null && res.data[0].asTenantUId == null){
            //     navigate("/checkout")
            // }

            if (res && res.data && isMounted.current == true) {
                let subDetails = res?.data[0];

                setSubscriptionUId(subDetails?.uId);
                setUser(subDetails?.noOfUsers);
                setTotalPrice(subDetails?.noOfUsers * subDetails?.amount);
                setFatureData(subDetails?.licenseType);
                handlePlanList(subDetails?.licenseTypeUId);

                if(subDetails?.noOfUsers == 0){
                    navigate("/checkout");
                }
                
                setBoughtPlanDetails((prevState) => {
                    return {
                        ...prevState, 
                        noOfUsers: subDetails?.noOfUsers,
                        amount: subDetails?.noOfUsers * subDetails?.amount,
                        planName: subDetails?.licenseType.name,
                        startDate: subDetails?.startDate,
                        endDate: subDetails?.endDate,
                        licenseTypeUId : subDetails?.licenseType?.licenseTypeUId
                    };
                });
            }
        })
    };


    const getSubscriptionPlanDetails = () => {
        getSubscriptionPlan()
            .then((response) => {
                if (response && isMounted.current == true) {
                    setSubscription(response?.data?.licenseTypes);
                    setdeveloperPrice(response?.data?.setUpPrice?.price)
                    // if(bbb.toLowerCase()=="beginer")
                    let filterData = props.pFlag ? null : params && response?.data?.licenseTypes.filter((data) => data.name.toLowerCase() == params.replace("%20", " ").toLowerCase())[0];
                    if (filterData) {
                        setFatureData(filterData)
                        setTotalPrice(filterData.pricePeryear)
                        handlePlanList(filterData?.licenseTypeUId)
                    }
                    else {
                        setFatureData(response?.data?.licenseTypes[0])
                        setTotalPrice(response?.data?.licenseTypes[0]?.pricePeryear)
                    }
                    if (props.pFlag == "update") {
                        getSubscriptionDetails();
                    }
                }
            })
    }

    const calculateNewPrice = () => {
        var totalCurrentPrice = user * featureData?.pricePeryear;
        setIsUpdatePayment(true);
        if(totalCurrentPrice > boughtPlanDetails.amount){
            var dayUsed = getDaysDifference(new Date(boughtPlanDetails.startDate),new Date());
            var unUsedPlanAmount = (((365 - dayUsed) / 365 * boughtPlanDetails.amount));
            var newPrice = (totalCurrentPrice) - (unUsedPlanAmount);
            setUnusedAmount(Math.round(unUsedPlanAmount));
            setTotalPrice(Math.round(newPrice));
        }
        else if(totalCurrentPrice < boughtPlanDetails.amount)
        {
            setUnusedAmount(0);
            setTotalPrice(0);
        }
    } 

    function getDaysDifference(startDate, endDate) {
        const timeDifference = endDate.getTime() - startDate.getTime();
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        return daysDifference;
    }

    const onHandlePlanList = (data) => {
        subscriptionList?.forEach((item) => {
            if (item.licenseTypeUId == data.licenseTypeUId) {
                setFatureData(item)
                isDeveloperSetUp ? setTotalPrice((user * item.pricePeryear) + (hour * developerPrice)) : setTotalPrice((user * item.pricePeryear));
            }
            handlePlanList(data.licenseTypeUId)
        })
    }

    // for updating plan list
    const handlePlanList = (licenseTypeUId) => {
        let planName = "";
        let newPlanList = [...fixedPlanList];
        newPlanList.forEach((item) => {
            item.isSelected = false;
            if (item.licenseTypeUId == licenseTypeUId) {
                item.isSelected = true;
                planName = item.planName;
            }
        })
        setPlanList([...newPlanList])
        handleMaxNoOfUser(licenseTypeUId);
        //const queryParam = queryString.parse(location.search);
        // delete queryParam[SUBSCRIPTION];

        const queryString = location.search;

        const urlParams = new URLSearchParams(queryString);
        const queryParam = {};
        for (const [key, value] of urlParams.entries()) {
          queryParam[key] = value;
        }
        delete queryParam[SUBSCRIPTION];

        updateUrl(queryParam, planName);
    }

    const handleMaxNoOfUser = (licenseTypeUId) => {
        var data = subscriptionList?.filter(i => i.licenseTypeUId == licenseTypeUId)[0];
        if(data){
            var totalNumberOfuser = data.features.filter(i => i.featureCD == "COLLECTION_USERS_LIMIT")[0].defaultValue;
            if(totalNumberOfuser != "NO LIMIT"){
                setUserList(usersList.slice(0, totalNumberOfuser))

            }
            else{
                setUserList(usersList)
            }
        }
    };

    const updateUrl = (queryParam, planName) => {
        // delete queryParam["FilterSource"];
        let domainpath = "";
        Object.keys(queryParam)
            .reverse()
            .forEach((key) => {
                domainpath = domainpath + "&" + key + "=" + queryParam[key];
            });
        domainpath = domainpath.substring(1);
        if (domainpath) {
            navigate(location.pathname + "?" + domainpath + "&subscription=" + planName);
        }
        else {
            navigate(location.pathname + "?" + "subscription=" + planName);
        }

    };

    const handleDeveloperSetUp = (isSelected) => {
        isSelected ? setTotalPrice((user * featureData.pricePeryear) + (hour * developerPrice)) : setTotalPrice((user * featureData.pricePeryear));
        setDeveloperSetUp(isSelected)
    }

    const updateSubscription = () => {
        setButtonDisabled(true);
        const parsedUrl = new URL(window.location.href);
        const redirectUri = parsedUrl.origin + "/connections"
        updateSubscriptionPlan(redirectUri, featureData.licenseTypeUId, user,subscriptionUId)
            .then((response) => {
                if(response?.hasError){
                    toast.error(response?.errorMessage)
                }
                else if(response?.data?.paymentStatus == "open"){
                    setPaymentPopup(true);
                    setPaymentUrl(response?.data?.redirectUrl);
                    //window.open(response?.data?.redirectUrl, "_blank")
                }
                else{
                    window.open(response?.data?.redirectUrl, "_self")
                }
                setButtonDisabled(false);
            }).catch = (error) => {
                setButtonDisabled(false);
            }
    }

    var popupWindow = null;
    const makeOpenInvoicePayment = () => {
       if(paymentUrl){
        popupWindow = window.open(
            paymentUrl,
            "_blank",
            "width=1000,height=700"
          )
       }

       const checkPopupClosed = setInterval(() => {
        if (popupWindow.closed) {
          // The popup window is closed
          clearInterval(checkPopupClosed);
      
          // Add your code to handle the closed popup window here
          popupWindow = null;
          setPaymentUrl(null);
          window.location.reload();
        }
      }, 1000);
    };



    const onBuyPlan = () => {
        setButtonDisabled(true);
        const parsedUrl = new URL(window.location.href);
        let data = {
            licenseTypeUId: isDeveloperSetUp ? AppEnum?.LicenseTypeUIds?.SUPPORT : featureData.licenseTypeUId,
            licenseUnit: user,
            boughtSupport: isDeveloperSetUp,
            supportUnit: isDeveloperSetUp ? hour : 0,
            redirectUrl: parsedUrl.origin + "/connections"
        }
        addBuyPlan(data)
            .then(async (response) => {
                setButtonDisabled(false);
                if(isDeveloperSetUp){
                    toast.success("A confirmation email will be sent to you shortly.");
                    return;
                }
                await refreshTokenHandler();
                window.open(response?.data?.entityUId, "_self")
            }).catch = (error) => {
                setButtonDisabled(false);
            }
    }
 
    return (
        <div className='checkout-main-div'>
            {
                props.pFlag && props.pFlag == BUYSETUP ?
                    <UpdateCheckoutPrice planList={planList}
                    onHandlePlanList={onHandlePlanList}
                    featureData={featureData}
                    developerPrice={developerPrice}
                    userList={userList}
                    hoursList={hoursList}
                    user={user}
                    setUser={setUser}
                    hour={hour}
                    setHour={setHour}
                    totalPrice={totalPrice}
                    isDeveloperSetUp={isDeveloperSetUp}
                    setDeveloperSetUp={handleDeveloperSetUp}
                    onBuyPlan={onBuyPlan}
                    buttonDisabled={buttonDisabled}
                    pFlag={props.pFlag}
                />
                     :
                        <CheckoutView planList={planList}
                        onHandlePlanList={onHandlePlanList}
                        featureData={featureData}
                        developerPrice={developerPrice}
                        userList={userList}
                        hoursList={hoursList}
                        user={user}
                        setUser={setUser}
                        hour={hour}
                        setHour={setHour}
                        totalPrice={totalPrice}
                        isDeveloperSetUp={isDeveloperSetUp}
                        setDeveloperSetUp={handleDeveloperSetUp}
                        pFlag={props.pFlag}
                        onBuyPlan={onBuyPlan}
                        updateSubscription={updateSubscription}
                        boughtPlanDetails={boughtPlanDetails}
                        isUpdatePayment={isUpdatePayment}
                        unusedAmount={unusedAmount}
                        subscriptionUId={subscriptionUId}
                        buttonDisabled={buttonDisabled}
                    />                    
            }

            {paymentPopup && <MuiDialog isShowPopUp={paymentPopup} 
                       titleDescription={"Please complete your payment by clicking on following make payment button."}
                       primaryButtonTitle={"make payment"}
                       primaryButtonAction={makeOpenInvoicePayment}
                       secondaryButtonAction={() => setPaymentPopup(false)}/>}

        </div>
    )
}

export default Checkout