import { Paper } from "@mui/material";
import { useState, useEffect } from "react";
import { FormContext } from "../../../../contexts/form-context";
import BasicAuthController from "../../../../controllers/connections/connection-details/rest-api/basic-auth-controller";
import { BearerToken } from "../../../../controllers/connections/connection-details/rest-api/bearer-token";
import CookieAuthContainer from "../../../../controllers/connections/connection-details/rest-api/cookie-auth-container";
import OAuth2Controller from "../../../../controllers/connections/connection-details/rest-api/o-auth-2.0-controller";
import { RestAPIKey } from "../../../../controllers/connections/connection-details/rest-api/rest-api-key";
import { RestApiNavData } from "../../../../services/data-source.utility.service";
import { CommonConnectionInputs } from "../common-connection-inputs";
import { RestApiNavView } from "./rest-api-nav-view";
import "./rest-api.scss";
import { isNullOrEmpty } from "../../../../services/data-source-validation";
import { RestServiceConstants } from "../../../../constants/rest-service-constant";

export const RestApiView = (props) => {
    const {
        loginUrlParameters,
        restApiKeyParameters,
        bearerParameters,
        handleChangeInput,
        formData,
        authTypeParameters,
        isFormInvalid,
        jwtTypeParameters,
        basicAuthParameters,
        oauth2Parameters,
        apiEndPointParameters,
        isTemplateConnection,
        isUpdatingTemplateConnection,
        saveFormData,
        setIsClientDataUpdated,
        existingClientData,
        requireProxyConnector,
        onCheckRequireProxyConnector,
        uID
    } = props;
    let navData = RestApiNavData;

    const [selectedAuth, setAuth] = useState(
        (formData &&
            navData.find(
                (ele) => ele.label === formData[authTypeParameters.parameterType]?.value
            )?.label) ||
        RestApiNavData[0].label
    );

    if (formData[authTypeParameters.parameterType])
        formData[authTypeParameters.parameterType].value = selectedAuth;

    if (isTemplateConnection || isUpdatingTemplateConnection) {
        navData = (formData && navData.filter(o => o.label === selectedAuth));
        navData[0].tabId = 0;
    }
    useEffect(()=>{
        getHeaderPrifix();
        getHeaderAuthorization();
    },[])

    const getHeaderPrifix = () => {
        formData[RestServiceConstants.BEARER_TOKEN.HEADER_PREFIX] = {
            value: formData[RestServiceConstants.BEARER_TOKEN.HEADER_PREFIX]?.value == null || formData[RestServiceConstants.BEARER_TOKEN.HEADER_PREFIX]?.value == "" ? "Bearer" : formData[RestServiceConstants.BEARER_TOKEN.HEADER_PREFIX]?.value,
            datasourceParameterTypeId: formData[RestServiceConstants.BEARER_TOKEN.HEADER_PREFIX]?.datasourceParameterTypeId,
            errorMsg: formData[RestServiceConstants.BEARER_TOKEN.HEADER_PREFIX]?.errorMsg,
            isValid: formData[RestServiceConstants.BEARER_TOKEN.HEADER_PREFIX]?.isValid
        };       
    };

    const getHeaderAuthorization = () => {       
        formData[RestServiceConstants.BEARER_TOKEN.Authorization_Header] = {
            value: formData[RestServiceConstants.BEARER_TOKEN.Authorization_Header]?.value == null || formData[RestServiceConstants.BEARER_TOKEN.Authorization_Header]?.value == "" ? "Authorization" : formData[RestServiceConstants.BEARER_TOKEN.Authorization_Header]?.value,
            datasourceParameterTypeId: formData[RestServiceConstants.BEARER_TOKEN.Authorization_Header]?.datasourceParameterTypeId,
            errorMsg: formData[RestServiceConstants.BEARER_TOKEN.Authorization_Header]?.errorMsg,
            isValid: formData[RestServiceConstants.BEARER_TOKEN.Authorization_Header]?.isValid
        };   
    };
    useEffect(() => {
        checkFormIsValid();
    },[formData])

    const checkFormIsValid = () => {
        const apiEndPoint = (!!formData && !!formData[apiEndPointParameters.parameterType] && formData[apiEndPointParameters.parameterType]).value

        if(isNullOrEmpty(apiEndPoint)){
            isFormInvalid(true);
        }

    };


    return (
        <Paper
            sx={{
                minHeight: "100px",
                width: "900px",
                padding: "20px",
            }}
        >
            <FormContext.Provider value={{ handleChangeInput }}>
                <CommonConnectionInputs
                    formData={formData}
                    apiEndPointParameters={apiEndPointParameters}
                    requireProxyConnector={requireProxyConnector}
                    onCheckRequireProxyConnector={onCheckRequireProxyConnector}
                    selectedAuth={selectedAuth}
                />
                <div className={`rest-nav-container ${(isTemplateConnection || isUpdatingTemplateConnection) ? 'template-connection' : ''}`}>
                    <RestApiNavView
                        navData={navData}
                        selectedTab={selectedAuth}
                        setTab={setAuth}
                    />
                </div>
                {AuthType(
                    selectedAuth,
                    formData,
                    restApiKeyParameters,
                    bearerParameters,
                    isFormInvalid,
                    jwtTypeParameters,
                    basicAuthParameters,
                    oauth2Parameters,
                    loginUrlParameters,
                    saveFormData,
                    setIsClientDataUpdated,
                    existingClientData,
                    uID
                )}
            </FormContext.Provider>
        </Paper>
    );
};

const AuthType = (
    selectedTab,
    formData,
    restApiKeyParameters,
    bearerParameters,
    isFormInvalid,
    jwtTypeParameters,
    basicAuthParameters,
    oauth2Parameters,
    loginUrlParameters,
    saveFormData,
    setIsClientDataUpdated,
    existingClientData,
    uID
) => {
    switch (selectedTab) {
        case RestApiNavData[0].label:
            return;
        case RestApiNavData[1].label:
            return (
                <div className="rest-api-container">
                    <RestAPIKey
                        formData={formData}
                        restApiKeyParameters={restApiKeyParameters}
                        isFormInvalid={isFormInvalid}
                    />
                </div>
            );
        case RestApiNavData[2].label:
            return (
                <div>
                    <BearerToken
                        formData={formData}
                        bearerParameters={bearerParameters}
                        isFormInvalid={isFormInvalid}
                        jwtTypeParameters={jwtTypeParameters}
                        loginUrlParameters={loginUrlParameters}
                    />
                </div>
            );
        case RestApiNavData[3].label:
            return (
                <div>
                    <OAuth2Controller
                        formData={formData}
                        bearerParameters={bearerParameters}
                        isFormInvalid={isFormInvalid}
                        oauth2Parameters={oauth2Parameters}
                        saveFormData={saveFormData}
                        setIsClientDataUpdated={setIsClientDataUpdated}
                        existingClientData={existingClientData}
                        uID={uID}
                    />
                </div>
            );
        case RestApiNavData[4].label:
            return (
                <div>
                    <BasicAuthController
                        formData={formData}
                        bearerParameters={bearerParameters}
                        isFormInvalid={isFormInvalid}
                        basicAuthParameters={basicAuthParameters}
                    />
                </div>
            );
        default:
            return <h3>Error</h3>;
    }
};
