import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography
} from "@mui/material";
import React, { useMemo } from "react";
import styles from "../../../styles/_exports.module.scss";
import { useParams } from "react-router-dom";
import './select-connection.scss';
import { AppEnum } from "../../../constants/app-enum";



const SelectConnection = (props) => {

    const { collectionUId: collectionUId } = useParams();

    const { connectionList, connectionUId } = props.state;

    const connectionCards = useMemo(() => {
        if (collectionUId) {
            const filteredByCollectionUId = connectionList.filter(i => i.uId === connectionUId);
            return filteredByCollectionUId?.length
                ? filteredByCollectionUId 
                : connectionList.filter(i => i.uId === AppEnum.MergeCollection.MergeCollectionUId);
        }
        return connectionList;
    }, [collectionUId, connectionList, connectionUId])

    return (
        <>
            <div className="conn-card-container-type">
                {
                    (connectionCards).map((card) => (
                        <Card
                            key={card.key}
                            sx={{
                                width: "261px",
                                border: card.uId === connectionUId
                                    ? `2px solid ${styles.primaryColor}` : "",
                                boxShadow: card.uId === connectionUId
                                    ? "none !important" : "",
                                backgroundColor: collectionUId ? "#F2F2F2" : "",
                            }}
                            className='connection-card'
                        >
                            <CardActionArea
                                onClick={() => props.onSelectConnectionHandler(card)}
                                className='card-action-area'
                                disabled={collectionUId ? true : false}
                            >
                                {
                                    card.iconImage &&
                                    <CardMedia
                                        component="img"
                                        sx={{ width: "auto !important", height: '44px' }}
                                        // image={`/assets/${card.datasourceTypeIconImage}`}
                                        // alt={card.datasourceTypeIconImage}
                                        image={`/assets/${card.iconImage}`}
                                        alt={card.iconImage}
                                    />
                                }

                                <CardContent className="connection-name">
                                    <Typography gutterBottom variant="body2" component="div" sx={{ marginBottom: '0px' }}>
                                        {card.name}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    ))
                }
            </div>
        </>
    );
};

export default SelectConnection;