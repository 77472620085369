import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddNewUser from "../../components/users-list/add-new-user/add-new-user";
import { AppEnum } from "../../constants/app-enum";
import _ from "lodash";
import { Alpha, RegexEmail } from "../../constants/regular-expression";
import * as UserService from "../../services/user-service";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

// new user request model
class NewUserModel {
  Name = "";
  email = "";
  password = "";
  RoleId = "";
  generatedPassword = null;
  isFullNameValid = true;
  isEmailValid = true;
  isPasswordValid = true;
  isUserRoleIdValid = true;
  isPasswordGeneratorOpen = false;
  isCopyTooltipOpen = false;
  isPasswordVisible = false;
  errorMessage = null;
}


const AddNewUserController = (props) => {
  // state
  const [state, setState] = useState(new NewUserModel());
  const [userRole, setUserRole] = useState([]);
  const navigate = useNavigate();
  const [inviteUserButtton, setInviteUserButtton] = useState(false);

  useEffect(()=>{
    if(state.isEmailValid && state.Name && state.RoleId){
      setInviteUserButtton(true);
    }
    else{
      setInviteUserButtton(false);
    }
  },[state]);

  // it routes to the previous page
  const cancelAddingUser = () => {
    props.handleCloseDialog();
    resetUserModelData();
    // navigate(-1);
  };
  useEffect(() => {
    getUserRoles();
  }, []);
  // it closes the generated password dialog
  const closePasswordDialog = () => {
    setState((prevState) => {
      return { ...prevState, isPasswordGeneratorOpen: false };
    });
  };

  const resetUserModelData = () =>{
    setState(new NewUserModel());
  }

  // it generates a random password, also opens the password dialog to copy the generated password
  const generatePassword = () => {
    let generatedPassword = createRandomPassword(
      AppEnum.RandomPassword.passwordLength
    );
    setState((prevState) => {
      return {
        ...prevState,
        isPasswordGeneratorOpen: true,
        generatedPassword: generatedPassword,
        password: "",
      };
    });
  };

  // whenever the role dropdown will be choosen it will set the new role Id state
  

  // it creates a unique random password using lodash fn
  const createRandomPassword = (length) => {
    var allowedChars = AppEnum.RandomPassword.allowedChars;
    var password = _.sampleSize(allowedChars, length || 12);
    return password.join("");
  };

  // it copies the generated password to clipboard which can be used to paste anywhere else
  const copyGeneratedPassword = () => {
    // copy to clipboard
    navigator?.clipboard?.writeText(state.generatedPassword);
    openCopyTooltip(true);
    setState((prevState) => {
      return { ...prevState, isPasswordGeneratorOpen: false };
    });

    setTimeout(() => {
      openCopyTooltip(false);
    }, 2000);
  };

  // it opens the tooltip which shows the message that password has been copied to the clipboard
  const openCopyTooltip = (isOpen) => {
    setState((prevState) => {
      return { ...prevState, isCopyTooltipOpen: isOpen };
    });
  };

  // it sets the password state when password is changed from textfield
  const onChangePasswordHandler = (event) => {
    setState((prevState) => {
      return {
        ...prevState,
        password: event.target.value,
        isPasswordValid: event.target.value ? true : false,
      };
    });

    if (state.errorMessage) clearErrorMessage();
  };

  // it displays the entered password
  const showHidePassword = () => {
    setState((prevState) => {
      return { ...prevState, isPasswordVisible: !prevState.isPasswordVisible };
    });
  };

  // it sets the full name state
  const onChangeFullNameHandler = (event) => {
    setState((prevState) => {
      return { ...prevState, Name: event.target.value };
    });

    if (!state.isFullNameValid) onBlurFullnameHandler(event);

    if (state.errorMessage) clearErrorMessage();
  };

  // when user looses focus it checks if fullname is valid or not
  const onBlurFullnameHandler = (event) => {
    if (event.target.value) {
      let isFullNameValid = Alpha.test(event.target.value);

      if (isFullNameValid)
        setState((prevState) => {
          return { ...prevState, isFullNameValid: true };
        });
      else
        setState((prevState) => {
          return { ...prevState, isFullNameValid: false };
        });
    }
  };

  // it sets the email state
  const onChangeEmailHandler = (event) => {
    setState((prevState) => {
      return { ...prevState, email: event.target.value };
    });

    if (!state.isEmailValid) onBlurEmailHandler(event);

    if (state.errorMessage) clearErrorMessage();
  };

  // when user looses focus it checks if email is valid or not
  const onBlurEmailHandler = (event) => {
    if (event.target.value) {
      let isEmailValid = RegexEmail.test(event.target.value);

      if (isEmailValid)
        setState((prevState) => {
          return { ...prevState, isEmailValid: true };
        });
      else
        setState((prevState) => {
          return { ...prevState, isEmailValid: false };
        });
    }
  };

  // it clears the error message
  const clearErrorMessage = () => {
    setState((prevState) => {
      return { ...prevState, errorMessage: null };
    });
  };

  // it sets the error message state
  const setErrorMessage = (message) => {
    setState((prevState) => {
      return {
        ...prevState,
        errorMessage: message,
      };
    });
  };

  // it collects all the infos and saves the new user details
  const saveNewUser = () => {
    if (state.Name && state.email  && state.RoleId && state.isEmailValid) {
      let userSignupModel = {
        Name: state.Name,
        email: state.email,
        // password: state.password,
        RoleId: state.RoleId,
      };
      let inviteExist = props?.invitedUserState?.users.find(i=> i.email == state?.email);
      if(inviteExist){
        toast.error("An invitation has already been sent to this user.");
        cancelAddingUser();
        return;
      }

      UserService.saveNewUser(userSignupModel).then((response) => {
        if (response?.hasError) {
          setErrorMessage(response?.errorMessage);
          toast.error(response?.errorMessage);
        }
        else{
          props.handleCloseDialog();
          resetUserModelData();
          props.getInvitedUsersList();
          //navigate("/users");
          toast.success("User invited successfully.");
        }
      });
    } else {
      setErrorMessage("Please fill all the required fields to proceed");

      if (!state.Name)
        setState((prevState) => {
          return { ...prevState, isFullNameValid: false };
        });

      if (!state.email)
        setState((prevState) => {
          return { ...prevState, isEmailValid: false };
        });

      if (!state.password)
        setState((prevState) => {
          return { ...prevState, isPasswordValid: false };
        });

      if (!state.RoleId)
        setState((prevState) => {
          return { ...prevState, isUserRoleIdValid: false };
        });
    }
  };

const onChangeRoleHandler = (event) => {
   
    setState((prevState) => ({
      ...prevState,
      RoleId: event.target.value,
      isUserRoleIdValid: event.target.value ? true : false,
    }));
  
    if (state.errorMessage) clearErrorMessage();
  };
  const getUserRoles = () => {

    UserService.getUserRoleList().then((response) => {
      setUserRole(response.data);
    });
  };

  return (
    <Fragment>
      <AddNewUser
        state={state}
        userRole={userRole}
        cancelAddingUser={cancelAddingUser}
        closePasswordDialog={closePasswordDialog}
        onChangeRoleHandler={onChangeRoleHandler}
        generatePassword={generatePassword}
        copyGeneratedPassword={copyGeneratedPassword}
        createRandomPassword={createRandomPassword}
        saveNewUser={saveNewUser}
        openCopyTooltip={openCopyTooltip}
        showHidePassword={showHidePassword}
        onChangePasswordHandler={onChangePasswordHandler}
        onChangeFullNameHandler={onChangeFullNameHandler}
        onChangeEmailHandler={onChangeEmailHandler}
        onBlurEmailHandler={onBlurEmailHandler}
        onBlurFullnameHandler={onBlurFullnameHandler}
        setState={setState}
        open={props.open}
        inviteUserButtton={inviteUserButtton}
        handleCloseDialog={() => {
          props.handleCloseDialog();
          resetUserModelData();
        }}

      />
    </Fragment>
  );
};

export default AddNewUserController;
