import React, { useMemo } from 'react'
import ASAutoComplete from '../../../shared/as-inputs/as-auto-complete';
import ASPaper from '../../../shared/misc/as-mui-paper';
import './merge-collections.scss';

const SelectCollections = (props) => {

    const { prevStepRef, dataCollections, onChangeObjectSelectHandler, selectedObjectCollections, isDataLoading } = props;

    const options = useMemo(() => {
        return dataCollections?.map((option) => ({
            key: option?.uId,
            label: option?.collectionName,
            connectionIconImage: option?.connectionIconImage
        }))
    }, [dataCollections])

    return (
        <ASPaper
            ref={prevStepRef}
            isDataLoading={isDataLoading}
        >
            <ASAutoComplete
                options={options}
                isMultiSelect={true}
                onChange={onChangeObjectSelectHandler}
                value={selectedObjectCollections}
                label={"Select collections"}
                tableContainerRef={prevStepRef}
                className='auto-complete-input'
            />
        </ASPaper >
    )
}

export default SelectCollections