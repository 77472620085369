import AddCircleIcon from "@mui/icons-material/AddCircle";
import { IconButton, MenuItem, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputConstants } from "../../../../constants/input-constant";
import { RestServiceConstants } from "../../../../constants/rest-service-constant";
import {
    Parameters,
    ParametersForm
} from "../../../../controllers/connections/connection-details/rest-api/bearer-token";
import { ASButton } from "../../../../shared/as-button/as-button";
import { ASInput } from "../../../../shared/as-inputs/as-input";
import { ASRadioGroup } from "../../../../shared/as-radio-group";
import { ASTab, ASTabs } from "../../../../shared/as-tabs/as-tabs";
import { ASTextField } from "../../../../shared/as-text-fields";
import themeColor from "./../../../../styles/_exports.module.scss";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { tableBorderRight, keyWidth, valueWidth , valueTypeWidth, parameterTypeWidth, variableNameWidth } from "../../../../controllers/data-collections/add-new-data-collection/add-new-data-collection";
import { selectedMenuItemBackgroundColor } from "../../../data-collections/collection-parameters/collection-parameters";
import Stack from '@mui/material/Stack';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';

const StyledAutocompletePopper = styled('div')(({ theme }) => ({

    [`& .${autocompleteClasses.listbox}`]: {
      fontSize: '13px !important',
  
      [`& .${autocompleteClasses.option}`]: {
        '&[aria-selected="true"]': {
          backgroundColor: `${themeColor.primaryColor} !important`,
          color: `white !important`
        },
  
        '&[data-focus="true"]': {
          backgroundColor: `${themeColor.primaryColor} !important`,
          color: `white !important`
        },
  
        '&:hover': {
          backgroundColor: `${themeColor.avatarBackground}`,
          color: `${themeColor.secondaryColor}`
        },
      },
    },
  
    [`&.${autocompleteClasses.popper}`]: {
      marginTop: '-1em',
    },
  }));

export const BearerTokenView = (props) => {

    const { formData, sendAs, jsonBody, parameters, onRadioChange, isFormInvalid, jwtTypeParameters,loginUrlParameters , headerPrefixParameter, bearerAuthorizationHeader} = props;

    const [activeTab, setActiveTab] = useState(0);

    const handleChange = () => {
        setActiveTab(+!activeTab);
    };

    return (
        <>
            <ASTabs className="mb-2" value={activeTab} onChange={handleChange}>
                <ASTab label="BASIC DETAIL" />
                <ASTab label="PARAMETERS" />
            </ASTabs>

            {!activeTab && (
                <BasicDetail
                    formData={formData}
                    onRadioChange={onRadioChange}
                    sendAs={sendAs}
                    jsonBody={jsonBody}
                    jwtTypeParameters={jwtTypeParameters}
                    loginUrlParameters={loginUrlParameters}
                    headerPrefixParameter={headerPrefixParameter}
                    bearerAuthorizationHeader={bearerAuthorizationHeader}
                />
            )}
            {!!activeTab && (
                <Parameters
                    formData={formData}
                    parameters={parameters}
                    isFormInvalid={isFormInvalid}
                    title={"Parameters"}
                />
            )}
        </>
    );
};

const BasicDetail = (props) => {
    const { formData, sendAs, jsonBody, onRadioChange,jwtTypeParameters,loginUrlParameters,headerPrefixParameter, bearerAuthorizationHeader } = props;
    const radioButtons = {
        name: "Send Body As",
        defaultValue: RestServiceConstants.BEARER_TOKEN.SEND_AS_OPTION[0].value,
        radioButtons: RestServiceConstants.BEARER_TOKEN.SEND_AS_OPTION,
        labelColor: themeColor.text_2,
        checkedLabelColor: themeColor.primaryColor,
    };
    const [bodyAs, setBodyAs] = useState(
        formData[sendAs.parameterType].value ||
        RestServiceConstants.BEARER_TOKEN.SEND_AS_OPTION[0].value
    );
    radioButtons.defaultValue = bodyAs;

    useEffect(() => onRadioChange(bodyAs), []);

    const onRadioInputChange = (value) => {
        onRadioChange(value);
        setBodyAs(value);
    };

    return (
      <>
        <Typography
          sx={{
            color: themeColor.text_2,
          }}
          variant="body1"
          display="block"
        >
          Send Body As
        </Typography>

        <ASRadioGroup
          radioInputs={radioButtons}
          onChange={(_e) => onRadioInputChange(_e.target.value)}
        />

        <ASInput
          value={
            (
              !!formData &&
              !!formData[loginUrlParameters.parameterType] &&
              formData[loginUrlParameters.parameterType]
            ).value
          }
          fieldParameter={{
            ...loginUrlParameters,
            displayName: loginUrlParameters.displayName || "Login URL"
          }}
        />

<       ASInput
          value={
            (
              !!formData &&
              !!formData[jwtTypeParameters.parameterType] &&
              formData[jwtTypeParameters.parameterType]
            ).value
          }
          fieldParameter={{
            ...jwtTypeParameters,
            displayName: jwtTypeParameters.displayName || "JWT Json Path"
          }}
        />

        <ASInput
          value={
            (
              !!formData &&
              !!formData[headerPrefixParameter.parameterType] &&
              formData[headerPrefixParameter.parameterType]
            ).value
          }
          fieldParameter={{
            ...headerPrefixParameter,
            displayName: headerPrefixParameter.displayName || "Header Prefix"
          }}
        />

        <ASInput
          value={
            (
              !!formData &&
              !!formData[bearerAuthorizationHeader.parameterType] &&
              formData[bearerAuthorizationHeader.parameterType]
            ).value
          }
          fieldParameter={{
            ...bearerAuthorizationHeader,
            displayName: bearerAuthorizationHeader.displayName || "Authorization Header"
          }}
        />

        {radioButtons.defaultValue ===
          RestServiceConstants.BEARER_TOKEN.SEND_AS_OPTION[0].value && (
          <>
            <Typography
              sx={{
                marginTop: "10px",
                color: themeColor.text_2,
              }}
              variant="body2"
              display="block"
            >
              JSON Body
            </Typography>

            <ASInput
              sx={{
                marginTop: "10px",
                width: "100%",
              }}
              multiline={true}
              rows={10}
              value={
                (
                  !!formData &&
                  !!formData[jsonBody.parameterType] &&
                  formData[jsonBody.parameterType]
                ).value
              }
              errorMsg={
                (
                  !!formData &&
                  !!formData[jsonBody.parameterType] &&
                  formData[jsonBody.parameterType]
                ).errorMsg
              }
              fieldParameter={{
                ...jsonBody,
                displayName: jsonBody.displayName || "",
                validateJSON: true,
              }}
            />
          </>
        )}
      </>
    );
};

export const ParametersView = (props) => {

    const { update, onAddRow, ok, remove, edit, data, cancel, isAddDisable, title, isAuthRequest} = props;

    return (
        <>
            <div className="add-collection-btn-div">
                <Typography
                    sx={{ color: themeColor.text_2, }}
                    variant="body1"
                    display="block"
                >
                    {title}
                </Typography>

                <IconButton
                    disabled={isAddDisable}
                    disableRipple
                    sx={{ color: themeColor.primaryButton, }}
                    onClick={() => onAddRow()}
                >
                    <AddCircleIcon sx={{ fontSize: '2rem' }} />
                    <Typography
                        sx={{ marginLeft: "5px", color: themeColor.text_2, }}
                        variant="body1"
                        display="block"
                    >
                        Add
                    </Typography>
                </IconButton>
            </div>

            <div className="w-100 parameters-container">

                {
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            size='small'
                            className='parameter-table'
                        >
                            <TableHead sx={{ minWidth: 750, borderTop: themeColor.borderBottom }}>

                                <TableRow>
                                    <TableCell align='left' scope="row" component={'th'} sx={keyWidth}  >
                                        Key
                                    </TableCell>

                                    <TableCell align='left' scope="row" component={'th'} sx={valueWidth}  >
                                        Value
                                    </TableCell>

                                    <TableCell align='left' scope="row" component={'th'} sx={parameterTypeWidth} >
                                        Parameter Type
                                    </TableCell>

                                    <TableCell align='left' scope="row" component={'th'} sx={valueTypeWidth}  >
                                        Value Type
                                    </TableCell>

                                    <TableCell align='left' scope="row" component={'th'}  sx={variableNameWidth}>
                                        Variable Name
                                    </TableCell>
                                </TableRow>

                            </TableHead>
                            <TableBody>
                                {
                                    data?.map((ele, index) => {

                                        return (

                                            !ele.edit ?
                                                <TableRow key={`data ${index}`} >

                                                    <TableCell align="left" component={'td'} sx={tableBorderRight}>
                                                        {ele?.key}
                                                    </TableCell>

                                                    <TableCell align="left" component={'td'} sx={tableBorderRight} >
                                                        {ele?.displayName ? ele?.displayName : ele?.value}
                                                    </TableCell>

                                                    <TableCell align="left" component={'td'} sx={tableBorderRight}  >
                                                        {ele?.type}
                                                    </TableCell>

                                                    <TableCell align="left" component={'td'} sx={tableBorderRight} >
                                                        {ele?.valueType}

                                                    </TableCell>

                                                    <TableCell align="left" component={'td'}
                                                    >
                                                        <div >
                                                            <ASButton sx={{
                                                                color: themeColor.linkColor
                                                            }} variant="text" onClick={() => edit(index)}>
                                                                EDIT
                                                            </ASButton>
                                                            |
                                                            <ASButton sx={{
                                                                color: themeColor.linkColor,
                                                                padding: '6px 15px'
                                                            }} variant="text" onClick={() => remove(index)}>
                                                                DELETE
                                                            </ASButton>
                                                        </div>

                                                    </TableCell>


                                                </TableRow>
                                                :
                                                <TableRow>
                                                    <TableCell colSpan={6} sx={{ borderBottom: 'none' }}>
                                                        <div className="row-wrapper body">
                                                            <div className="form-wrapper">
                                                                <ParametersForm
                                                                    remove={remove}
                                                                    update={update}
                                                                    data={ele}
                                                                    ok={ok}
                                                                    index={index}
                                                                    cancel={cancel}
                                                                    isAuthRequest={isAuthRequest}
                                                                />
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </div>
        </>
    );
};

export const ParametersFormView = (props) => {
    const { data, ok, cancel, handleChange, isSaveEnable, variableList, onSelectVariableHandler, isAuthRequest } = props;

    return (
        <>
            <div className="w-100 border-bottom ">
                <div className="w-85 d-flex flex-wrap">
                    <div className="w-50 parameter-input-div">
                        <div className="parameter-input-pad">
                            <ASTextField
                                className="text-field"
                                type={InputConstants.text}
                                fullWidth
                                label="Key *"
                                variant="outlined"
                                value={data.key}
                                onChange={(_event) => handleChange("key", _event.target.value)}
                            ></ASTextField>
                        </div>
                    </div>

                    <div className="w-50 parameter-input-div">
                        <div className="parameter-input-pad">
                            <ASTextField
                                className="text-field"
                                type={InputConstants.text}
                                fullWidth
                                label="Parameter Type *"
                                variant="outlined"
                                value={data.type}
                                onChange={(_event) => handleChange("type", _event.target.value)}
                                select={true}
                                SelectProps={{ MenuProps: { className: 'menu-role-div', sx: selectedMenuItemBackgroundColor } }}
                            >
                                <MenuItem value="Header" disabled={isAuthRequest}>Header</MenuItem>
                                <MenuItem value="Query String">Query String</MenuItem>
                                <MenuItem value="Form Data" disabled={isAuthRequest}>Form Data</MenuItem>
                            </ASTextField>
                        </div>
                    </div>

                    <div className="w-50 parameter-value-div">
                        <div className="parameter-input-pad">
                            <ASTextField
                                className="text-field"
                                type={InputConstants.text}
                                fullWidth
                                label="Value Type *"
                                variant="outlined"
                                value={data.valueType}
                                onChange={(_event) =>
                                    handleChange("valueType", _event.target.value)
                                }
                                select={true}
                                SelectProps={{ MenuProps: { className: 'menu-role-div', sx: selectedMenuItemBackgroundColor } }}
                            >
                                <MenuItem value="Fixed">Fixed</MenuItem>
                                <MenuItem value="User Input" disabled={true}>User Input</MenuItem>
                            </ASTextField>
                        </div>
                    </div>

                    <div className="w-50 parameter-value-div">
                        <div className="parameter-input-pad">
                            <Stack spacing={2}>
                                <Autocomplete
                                id="parameter-variable"
                                freeSolo
                                className="text-field"
                                options={variableList.map((option) => ({
                                    sysDataTypeId: option.sysDataTypeId,
                                    label: option.name,
                                    uId: option.uId,
                                }))}
                                renderOption={(props, option) => {
                                    return (
                                    <MenuItem
                                        {...props}
                                        key={`option ${option.uId}`}
                                        className={
                                            data.value === option.displayName
                                            ? "selected-opt"
                                            : "autocomplete-option"
                                        }
                                    >
                                        {option.label}
                                    </MenuItem>
                                    );
                                }}
                                onChange={(event, variable) => {
                                    onSelectVariableHandler(variable);
                                  }}
                                value={data.displayName ? data.displayName : data.value}
                                PopperComponent={StyledAutocompletePopper}
                                renderInput={(params) => (
                                    <ASTextField
                                    {...params}
                                    onChange={(event) => {
                                        handleChange("value", event.target.value)
                                        handleChange("displayName", "")
                                    }}
                                    label={"Type a Fixed Value or Select from a System Defined or User Defined Variable"}
                                    value={data.displayName ? data.displayName : data.value}
                                    />
                                )}
                                />
                            </Stack>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-100 py-2">
                <div className="d-flex justify-content-end">
                    <div className="px-2 parameter-cancel-btn">
                        <ASButton onClick={cancel}>CANCEL</ASButton>
                    </div>

                    <div className={isSaveEnable ? "px-2 parameter-ok-btn" : "px-2 collection-ok-btn"}>
                        <ASButton onClick={ok} primary={true} disabled={isSaveEnable}>
                            OK
                        </ASButton>
                    </div>
                </div>
            </div>
        </>
    );
};
