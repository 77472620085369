import { useState, useRef } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { ThemeProvider, createTheme } from "@material-ui/core";
import { sql, SQLDialect } from "@codemirror/lang-sql";
import { vscodeKeymap } from "@replit/codemirror-vscode-keymap";
import { EditorView, keymap } from "@codemirror/view";


const KEYWORDS = [
    "select",
    "from",
    "where",
    "and",
    "or",
    "not",
    "in",
    "between",
    "contains",
    "array",
    "limit",
    "offset",
    "union",
    "intersect",
    "except",
    "order by",
    "asc",
    "desc",
    "like",
    "join"
];

const TENSORS = ["images", "labels"];

const ASSqlQueryEditor = (props) => {


    const { isExpandedView, onChange, value } = props;


    const lightTheme = createTheme();

    const dialect = SQLDialect.define({
        keywords: KEYWORDS.join(" "),
        builtin: TENSORS.join(" ")
    });

    const [theme, setTheme] = useState(lightTheme);
    
    const codeTheme = EditorView.theme({
        "&": {
            background: theme.palette.background.paper,
            color: theme.palette.text.primary,
            border: "1px solid rgba(189,189,189,1)",
            height: isExpandedView ? 'calc(100vh - 192px)' : "180px",
            maxHeight: isExpandedView ? '' : "180px",
            overflowY: "auto",
            borderRadius: "4px",
            outline: "none !important"
        },
        ".cm-line": {
            background: "none"
        },
        ".cm-line .ͼb": {
            color: theme.palette.primary.light
        },
    });

    const codeMirrorRef = useRef(null);

    return (
        <ThemeProvider theme={theme}>
            <CodeMirror
                ref={codeMirrorRef}
                indentWithTab={false}
                extensions={[sql({ dialect }), keymap.of(vscodeKeymap)]}
                onChange={onChange}
                theme={codeTheme}
                value={value}
            />
        </ThemeProvider>
    )
}

export default ASSqlQueryEditor;