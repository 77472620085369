import React, { useEffect, useState } from 'react'
import SendBodyAs from '../../../../components/data-collections/send-body-as/send-body-as'
import { RestServiceConstants } from '../../../../constants/rest-service-constant';
import themeColor from "../../../../styles/_exports.module.scss";

export default function SendBodyAsController(props) {

    const { prevStepRef, state, setState, activeStep, setActiveStep, isShowStep, showSaveChangesButton } = props;

    const radioButtons = {
        name: "Send Body As",
        defaultValue: RestServiceConstants.HTTPMETHODS.SEND_BODY_AS_OPTION[0].value,
        radioButtons: RestServiceConstants.HTTPMETHODS.SEND_BODY_AS_OPTION,
        labelColor: themeColor.text_2,
        checkedLabelColor: themeColor.primaryColor,
    };
    const [bodyAs, setBodyAs] = useState(state.restSendAsCD ? state.restSendAsCD : RestServiceConstants.HTTPMETHODS.SEND_BODY_AS_OPTION[0].value);

    radioButtons.defaultValue = bodyAs;

    useEffect(() => {
        if (isShowStep) {
            setActiveStep(activeStep + 1);
        }
        setState((prevState) => { return { ...prevState, restSendAsCD: state.restSendAsCD ? state.restSendAsCD : RestServiceConstants.HTTPMETHODS.SEND_BODY_AS_OPTION[0].value } });
    }, [])

    const onRadioInputChange = (value) => {
        setState((prevState) => { return { ...prevState, restSendAsCD: value } });
        setBodyAs(value);
        showSaveChangesButton(true);
    };

    const onChangeRestBodyHandler = (value) => {
        setState((prevState) => { return { ...prevState, restBody: value } });
        showSaveChangesButton(true);
    }


    return (
        <SendBodyAs onRadioInputChange={onRadioInputChange}
            state={state}
            radioButtons={radioButtons}
            prevStepRef={prevStepRef}
            onChangeRestBodyHandler={onChangeRestBodyHandler}
        />
    )
}
