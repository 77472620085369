import React from 'react'
import "./CheckoutView.scss";
import CalculateCheckoutView from '../../common/calculateCheckout/CalculateCheckoutView';
import { ASheetCompleteLogoSvg } from '../../../constants/common-svgs';

function CheckoutView({ planList, onHandlePlanList, featureData, developerPrice, userList, hoursList, user,
  setUser, isUpdatePayment, unusedAmount,
  hour, updateSubscription, subscriptionUId,
  setHour, totalPrice, isDeveloperSetUp, setDeveloperSetUp, pFlag, onBuyPlan, boughtPlanDetails, buttonDisabled }) {
  return (
    <div className="mainDiv">
      <div className="rightDiv">
        <div>
          {
            !pFlag && <><div className='asheet-logo'><span>{ASheetCompleteLogoSvg}</span>
            </div></>
          }
        </div>

        <div><CalculateCheckoutView planList={planList} onHandlePlanList={onHandlePlanList}
          featureData={featureData} developerPrice={developerPrice}
          userList={userList}
          hoursList={hoursList}
          user={user}
          setUser={setUser}
          hour={hour}
          setHour={setHour}
          totalPrice={totalPrice}
          isDeveloperSetUp={isDeveloperSetUp}
          setDeveloperSetUp={setDeveloperSetUp}
          pFlag={pFlag}
          onBuyPlan={onBuyPlan}
          boughtPlanDetails={boughtPlanDetails}
          isUpdatePayment={isUpdatePayment}
          updateSubscription={updateSubscription}
          unusedAmount={unusedAmount}
          subscriptionUId={subscriptionUId}
          buttonDisabled={buttonDisabled}
        />
        </div>
      </div>
      <div>
        {
          !pFlag && <div className="leftDIv">
            <div className='display-contact'>
              <div className='display-contactus-text'>Need Help ?</div>
              <label>support@alphasheet.io</label>
            </div>
            <div className='description-section'>
              <label>Enjoy the 30 Days Trial of Full Packed AlphaSheet
                Solution and pay only if you like our product.
              </label>
            </div>
            <div className='description-section'>
              <label>Trust us - We are time saver</label>
            </div>

            <div className='logo'>
              <img src="./assets/payLater.png" alt="logo" />
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default CheckoutView