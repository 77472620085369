import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ReactPlayer from "react-player";

export const ASHelpPopup = ({ isOpen, onClose }) => {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth={false}
            PaperProps={{
                sx: {
                    width: "68.3%",
                    // maxWidth: "1200px",
                    height: "100%",
                    // maxHeight: "74vh",
                    overflow: "hidden"
                },
            }}
        >
            <DialogTitle sx={{ color: "#16365F", padding: "8px 25px" }}>
                How to use AlphaSheet
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 4,
                        top: 2,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent
                sx={{
                    padding: 0, 
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden"
                }}>
                <ReactPlayer
                    url="https://youtu.be/BqevRAJFX6I"
                    controls={true}
                    playing={isOpen}
                    width="100%"
                    height="100%"
                    style={{ backgroundColor: "transparent" }}
                />
            </DialogContent>
        </Dialog>
    );
};
