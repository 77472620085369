import { AppEnum } from "./app-enum";

export class DataCollectionConstants {
    static MergeQueryObj = {
        baseConnectionTypeId: null,
        connectionTypeDescription: "Merge collection",
        connectionTypeName: "Merge collection",
        description: "",
        iconImage: "mergecollection.svg",
        id: 'mergeQueryId',
        isActive: true,
        isTemplateConnection: null,
        lastAccessed: null,
        modifiedDateTime: null,
        name: "Merge collection",
        parameters: null,
        sysConnectionTypeId: AppEnum.MergeCollection.MergeSysConnectionTypeId,
        templateConnectionId: null,
        totalCollection: 0,
        uId: AppEnum.MergeCollection.MergeCollectionUId
    };
}