import ASSqlQueryEditor from '../../../shared/as-inputs/as-sql-editor';
import ASPaper from '../../../shared/misc/as-mui-paper';

const MergeQuery = (props) => {

    const { onChangeSqlEditorHandler, sqlEditorValue, prevStepRef } = props;


    return (
        <ASPaper
            ref={prevStepRef}
            isDataLoading={false}
        >
            <ASSqlQueryEditor
                isExpandedView={false}
                onChange={onChangeSqlEditorHandler}
                value={sqlEditorValue}
            />
        </ASPaper>
    )
}

export default MergeQuery