import React, { useEffect, useRef, useState } from 'react'
import { UsersPermissionState } from './users-permission-state';
import UsersPermissionForCollection from '../../../components/data-collections/users-permission-for-collection/users-permission-for-collection';
import * as collectionService from '../../../services/data-collection.service';
import * as userService from '../../../services/user-service';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { AppEnum } from '../../../constants/app-enum';


const UsersPermissionForCollectionController = () => {

    const [state, setState] = useState(new UsersPermissionState());
    const searchUserRef = useRef(null);
    const { collectionUId: collectionUId } = useParams();


    useEffect(() => {
        getUserListByCollectionUId();
    }, [])


    const onCheckAllUserHandler = async (event) => {
        let userList = state.userList
            .filter(user => user?.userRole !== AppEnum.UserRoleIds.CompanyAdmin &&
                user?.userRole !== AppEnum.UserRoleIds.CollectionAdmin)
            .map(user => ({ ...user }));

        let currentPermissionCount = 0;

        let users = checkUncheckAllCheckboxes(state.userList, event?.target?.checked);

        setState((prevState) => { return { ...prevState, userList: users, } });

        if (event?.target?.checked) {

            let totalPermissionCount = userList.filter((permission) => permission.hasPermission === false)?.length;

            await userList?.forEach((user) => {
                if (!user.hasPermission) {
                    currentPermissionCount++;
                    savePermission(true, user, false, totalPermissionCount, currentPermissionCount);
                }
            })
        }
        else {
            let totalPermissionCount = userList.filter((permission) => permission.hasPermission === true)?.length;

            await userList?.forEach((user) => {
                if (user.hasPermission) {
                    currentPermissionCount++;
                    savePermission(false, user, false, totalPermissionCount, currentPermissionCount);
                }
            })
        }
    }

    // It checks or unchecks the checkboxes instantly
    const checkUncheckAllCheckboxes = (data, isChecked) => {

        _.forEach(data, function (item) {

            if (isChecked)
                _.set(item, 'hasPermission', true);
            if (!isChecked)
                _.set(item, 'hasPermission', false);
        })
        return data;
    }


    const getUserListByCollectionUId = () => {
        collectionService.getUserListByCollectionUId(collectionUId)
            .then((result) => {

                if (result?.data)
                    setState((prevState) => { return { ...prevState, userList: result.data } });
            })
    }

    // searches the users by the entered key
    const onChangeSearchUserHandler = (searchedKey) => {

        let searchedUserList = [];

        if (searchedKey) {
            let userList = state.userList;
            searchedUserList = [];

            _.forEach(userList, function (user) {
                if (_.includes(user?.fullName?.toLowerCase().trim(), searchedKey?.toLowerCase().trim()))
                    searchedUserList.push(user);
            })
        }
        else
            searchUserRef.current.value = '';

        setState((prevState) => { return { ...prevState, searchedUserList: searchedKey ? searchedUserList : [] } });
    }

    const onChangeSearchUserDebouncedHandler = _.debounce(onChangeSearchUserHandler, 300)


    // it saves user's permission for collection
    const saveUserPermission = (hasPermission, user) => {

        if (user?.userRole === AppEnum.UserRoleIds.CompanyAdmin) {
            return;
        }

        let userList = state.userList;
        let connectionPermissionData = checkUncheckCheckbox(userList, hasPermission, user);

        setState((prevState) => { return { ...prevState, userConnectionPermission: connectionPermissionData } });

        savePermission(hasPermission, user);
    }

    const savePermission = (hasPermission, user, isFetchData = true, totalPermissionCount, currentPermissionCount) => {

        let userPermission = {
            collectionUId: collectionUId,
            userId: user.id,
            hasPermission: hasPermission
        }

        userService.saveCollectionPermission(userPermission).then((response) => {

            if ((response?.data && isFetchData) || (!isFetchData && totalPermissionCount === currentPermissionCount))
                getUserListByCollectionUId();
        })
            .catch((error) => {
                getUserListByCollectionUId();
            })
    }

    // It checks or unchecks the checkboxes instantly
    const checkUncheckCheckbox = (data, hasPermission, chosenRow) => {

        _.forEach(data, function (item) {
            let uId = (item.id === chosenRow.id);

            if (uId && hasPermission)
                _.set(item, 'hasPermission', true);
            if (uId && !hasPermission)
                _.set(item, 'hasPermission', false);
        })
        return data;
    }

    return (
        <div>
            <UsersPermissionForCollection
                state={state}
                onCheckAllUserHandler={onCheckAllUserHandler}
                searchUserRef={searchUserRef}
                onChangeSearchUserHandler={onChangeSearchUserDebouncedHandler}
                saveUserPermission={saveUserPermission}
            />
        </div>
    )
}

export default UsersPermissionForCollectionController;