import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import SelectObject from '../../../../components/data-collections/select-object/select-object';


const SelectObjectController = (props) => {

    const { state, setState, prevStepRef, selectedConnection, getCollectionObjectByType } = props;
    const { cloudCollectionObjects } = props.state;
    const [selectedObjectUId, setSelectedObjectUId] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const { collectionUId: collectionUId } = useParams();

    useEffect(() => {
        if ((selectedConnection && !state?.templateCollectionId) || collectionUId) {
            getCollectionObjectByType();
        }
    }, [selectedConnection]);


    useEffect(() => {
        if (state?.templateCollectionId) {
            setSelectedObjectUId(state?.templateCollectionId)
        }
    }, [])


    const handleObjectSelection = (event) => {
        setSelectedObjectUId(event.target.value);
        let object = cloudCollectionObjects?.find(i => i.templateCollectionId == event.target.value);
        if (object) {
            setState((prevState) => {
                const { dynamicListCollectionModel, dynamicListOptions, ...rest } = prevState;
                return {
                    ...rest,
                    templateCollectionId: object?.templateCollectionId,
                    restDataTransformationScript: object?.restDataTransformationScript,
                    collectionParameters: prevState.collectionParameters.filter(param =>
                        ["Filter", "Sort", "Limit"].includes(param.parameterName)
                    )
                };
            });
        }
    };




    return (
        <SelectObject
            state={state}
            prevStepRef={prevStepRef}
            selectedObjectUId={selectedObjectUId}
            handleObjectSelection={handleObjectSelection}
            collectionObjects={cloudCollectionObjects}
            errorMessage={errorMessage}
        />
    );
}

export default SelectObjectController;