import { useEffect, useState } from 'react';
import MergeCollectionList from '../../../../components/data-collections/column-selection-popup/merge-collection-list/merge-collection-list';
import NativeDataVisualizerController from '../../NativeDataVisualizer/native-data-visualizer-controller';
import DataVisualizerController from '../../DataVisualizer/data-visualizer-controller';

export default function MergeCollectionListController(props) {

    const { state, mergedCollectionsData } = props;

    const [showNativeDataVisualizer, setShowNativeDataVisualizer] = useState(false);
    const [showDataVisualizer, setShowDataVisualizer] = useState(false);
    const [selectedCollectionUId, setSelectedCollectionUId] = useState('');

    const openDataVisualizer = (e, collection) => {
        setSelectedCollectionUId(collection.uId)

        if (collection?.templateCollectionId) {
            setShowDataVisualizer(true);
        }
        else {
            setShowNativeDataVisualizer(true);
        }
    };

    return (
        <>
        <MergeCollectionList
            state={state}
            mergedCollectionsData={mergedCollectionsData}
            openDataVisualizer={openDataVisualizer}
        />
        {showNativeDataVisualizer &&
            <NativeDataVisualizerController
                collectionUId={selectedCollectionUId}
                showNativeDataVisualizer={showNativeDataVisualizer}
                setShowNativeDataVisualizer={setShowNativeDataVisualizer} />}

        
        {showDataVisualizer && selectedCollectionUId &&
            <DataVisualizerController isForDataCollections={true}
                closeWindowOnPopupClose={true}
                collectionUId={selectedCollectionUId}
                setShowDataVisualizer={setShowDataVisualizer}
                showDataVisualizer={showDataVisualizer} />
        }
        </>
    )
}
